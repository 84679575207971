import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Migrate fresh`}</h2>
    <GifPlayer mdxType="GifPlayer">
      <img {...{
        "src": "/d5efbb1577a4f955b49f82d81eabc1d3/gif.gif",
        "alt": "IBM Cloud Pictograms"
      }}></img>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "409px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.94444444444444%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADMUlEQVQozx3MbVCTBQDA8ccLGHPYdbnzTuyCk9BDYQxwwh632GDES0sYQnBEcuB5qHC82MiOUkFqKMJJhIqQL3AwD0VQ5CXc1ggBYUNOxgi1NFLRsutb3/9dffp9+wndQzZae/poutRNjsVM3pmvaL89QvvgGF22CQZcC7ie/8no/H3uOG/x8x+vGX/6nGHPI0Y8yzgfPWFmZZXZlVXcv64gTC89xn7fy9TSU0ra6gnMU/P92Cg3p+bpst9leNZD3WA/yiP5xB/NpsZmZXjhId3OGfqm5vhx6Reci8uMuubomxhHGLBP0u+YxjHr5eR1K+8WJdK/MM/c76958OwvWhx3kB/MRV6azfqSPQSfKscy6cDm9nLNOY3dtcBVu539TdWYThxAaLfe5GLvENZBB71jE0QdKWR9ro78lkbMXZ3IC7Lw36Pnrb2JbDycS7ClnJhzFlyrL3n89z/MrL6kxz1BUJEO09eHEIq/OErpl7VU1TVwvOE7jMfMCHFBCFGBvPGRFokpCUnyDnzFUPySInizIIWA0k9Jb21kX0crkZ8XIi/Uk2wpx/qDHaGq7Vs+a22ivOUUZWfqOdhcz+biLNbsCkGapmKtSYNEr8BHFYKPdiv+u9Ws25eJT6oaIToIISmM+G/MtPXdornDinB+fIwLk3Yu3HPQMeOg0z1O1VAPsvxkZBlqAjLj8E9V4qfZgq9mC7L0nQTkaPFVbUZQbiK0Ip+ShpNUnrBQWWP5L7Rx2T1J96KL60883H6xzGnvXeTVB1ibk4AsYyf+xkhkHypZtzsGWboKaaqSNbEhBJflUj3aS3XnecrONnKo0YIgpmSQnFdAVlkFRbU1HL9ykexzpwk8XMzbeWnIjDuQpCqQGpVITTFIMqKRpqvw259JgbOX/meLXPHco8PlpPWnEYSwKJHwGA1hSpGtCjWhEbFsj9Oh1KWwXWfgvfe1BMXH8U6SyKYUNRvTNWz4JI0NNWb2zg5w49US/a8ecuOFl2u/PUBQxMYTvUtPlKhDKSYSJRqIFA1EiAbC4/SEqxNQiAn/GyEmEqk1sE1nYNsHRlQf55BVUUFRXS2VZ5s51nOJfwGsPwd2cvYgewAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Cloud Pictograms",
          "title": "IBM Cloud Pictograms",
          "src": "/static/c677de230aed2dc4063382c3912ae364/e7e2f/gif.png",
          "srcSet": ["/static/c677de230aed2dc4063382c3912ae364/7fc1e/gif.png 288w", "/static/c677de230aed2dc4063382c3912ae364/e7e2f/gif.png 409w"],
          "sizes": "(max-width: 409px) 100vw, 409px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </GifPlayer>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`php bin/console doctrine:database:drop --if-exists --force \\
&& php bin/console doctrine:database:create --if-not-exists \\
&& php bin/console doctrine:migrations:migrate --no-interaction
`}</code></pre>
    <p><strong parentName="p">{`Seed`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`php bin/console doctrine:fixtures:load --no-interaction --append
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      